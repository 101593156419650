<template>
    <div>
        <div class="card bs-4">
            <div class="fl-te-c">
                <h4 class="mb-3">FEATURED BANK DETAILS</h4>
                <btn text="Back" @click="$router.push('/cms/featured-banks/')" size="sm"/>
            </div>
            <edit-client v-if="details" :initial-data="details" @success="loadDetails"/>
            <p v-else class="text-center p-3">No data available.</p>
        </div>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '@/data/cms-urls';
import EditClient from './EditClient';

export default {
    name       : 'DetailsPage',
    components : { EditClient },
    data () {
        return {
            loading : false,
            details : '',
            id      : this.$route.params.id
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods : {
        async loadDetails () {
            this.loading = true;
            const response = await axios.form(urls.cms.featured_bank.details, { id : this.id });
            const json = response.data;
            if (json.error === false) {
                this.details = json.data;
            } else {
                this.$notify('Could not load data, Please try again later', 'Error', {
                    type : 'danger'
                });
            }
            this.loading = false;
        }
    }
};
</script>

<style scoped>

</style>
